import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import { getMemberName } from "./MemberFormStep1";
var HtmlToReactParser = require("html-to-react").Parser;

class MemberFormStep3 extends Component {
  state = {
    datasource: {
      province: [],
    },
  };

  getAPIDatasource = () => {
    const api_url = "datasource";
    const params = {
      tables: [{ name: "province" }],
    };

    const promise = axios
      .post(api_url, params)
      .then((res) => {
        const datasource = {
          province: res.data.province.map((item) => {
            return { key: item.id, label: item.name };
          }),
        };

        return datasource;
      })
      .catch((res) => {
        console.log("error", res);
      });

    return promise;
  };

  getDocumentDeliveryAddress = () => {
    const step2 = this.props.step2;

    if (step2.delivery_document_type === "receipt") {
      return this.getReceiptDeliveryAddress();
    } else {
      let document_delivery_address = "";

      if (step2.document_name !== undefined && step2.document_name !== null) {
        document_delivery_address += step2.document_name + " ";
      }

      document_delivery_address += step2.document_address + " ";

      if (step2.document_sub_district) {
        document_delivery_address += "ตำบล" + step2.document_sub_district + " ";
      }

      if (step2.document_district) {
        document_delivery_address += "อำเภอ" + step2.document_district + " ";
      }

      if (step2.document_province_id) {
        const province_name = this.state.datasource.province.find(
          (f) => f.key == step2.document_province_id
        ).label;
        document_delivery_address += "จังหวัด" + province_name + " ";
      }

      document_delivery_address += step2.document_zip_code;

      return document_delivery_address;
    }
  };

  getReceiptDeliveryAddress = () => {
    let receipt_delivery_address = "";

    const step2 = this.props.step2;

    if (step2.receipt_name !== undefined && step2.receipt_name !== null) {
      receipt_delivery_address += step2.receipt_name + " ";
    }

    receipt_delivery_address += step2.receipt_address + " ";

    if (step2.receipt_sub_district) {
      receipt_delivery_address += "ตำบล" + step2.receipt_sub_district + " ";
    }

    if (step2.receipt_district) {
      receipt_delivery_address += "อำเภอ" + step2.receipt_district + " ";
    }

    if (step2.receipt_province_id) {
      const province_name = this.state.datasource.province.find(
        (f) => f.key == step2.receipt_province_id
      ).label;
      receipt_delivery_address += "จังหวัด" + province_name + " ";
    }

    receipt_delivery_address += step2.receipt_zip_code;

    return receipt_delivery_address;
  };

  componentDidMount() {
    this.getAPIDatasource().then((result) => {
      this.setState({
        datasource: result,
      });
    });
  }

  render() {
    let document_delivery_address = "";
    let receipt_delivery_address = "";

    if (this.state.datasource.province.length > 0) {
      document_delivery_address = this.getDocumentDeliveryAddress();
      receipt_delivery_address = this.getReceiptDeliveryAddress();
    }

    const members = this.props.step1.map((item, key) => {
      return (
        <tr key={key}>
          <td>{getMemberName(item, this.props.datasource)}</td>
          <td className="text-center">{item.identity_number}</td>
        </tr>
      );
    });

    return (
      <div className="tab-pane" role="tabpanel" id="complete">
        <h3>สรุปผล</h3>
        <div className="text-left p-5">
          <strong>ที่อยู่ในการออกใบเสร็จ: </strong>
          {receipt_delivery_address}
          <br />
          <strong>ที่อยู่ในการจัดส่งเอกสาร: </strong>
          {document_delivery_address}
          <br />
          <strong>สมาชิก: </strong>
          <br />
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">ชื่อ - นามสกุล</th>
                <th className="text-center">เลขที่บัตรประชาชน</th>
              </tr>
            </thead>
            <tbody>{members}</tbody>
          </table>
          <hr />
          {this.props.url_preview_receipt === null && (
            <small className="text-muted">กำลังโหลดตัวอย่างใบเสร็จ...</small>
          )}
          {this.props.url_preview_receipt && (
            <>
              <iframe
                className="w-100"
                style={{ height: "100vh" }}
                src={this.props.url_preview_receipt}
              ></iframe>
              <hr />
            </>
          )}
          <div className="jumbotron pb-2 mb-0">
            <h3 className="text-center">ข้อตกลง</h3>
            <p style={{ textIndent: "20px" }}>
              ด้วยสถาบันได้เข้าระบบการออกใบเสร็จรับเงิน/ใบกำกับภาษีแบบอิเล็กทรอนิกส์
              (e-Tax Invoice & e-Receipt)
              และนำส่งข้อมูลไปยังกรมสรรพากรในรูปแบบอิเล็กทรอนิกส์ทันที
              เมื่อดำเนินการยืนยันการส่งใบเสร็จรับเงิน/ใบกำกับภาษี&nbsp;
              <strong className="text-danger">
                จึงไม่สามารถแก้ไขหรือเปลี่ยน ชื่อ-ที่อยู่ เลขประจำตัวผู้เสียภาษี
                สำนักงานใหญ่/สาขา ได้ทุกกรณี{" "}
              </strong>{" "}
            </p>
            <p style={{ textIndent: "20px" }}>
              เพื่อความถูกต้องของใบเสร็จรับเงิน/ใบกำกับภาษีแบบอิเล็กทรอนิกส์
              ที่ท่านจะได้รับ
              ทางสถาบันใคร่ขอให้ท่านโปรดตรวจสอบรายละเอียดการลงทะเบียน
              ก่อนกดยืนยันการส่งข้อมูลให้แก่สถาบันทุกครั้ง ประกอบด้วย ชื่อ-
              ที่อยู่, เลขประจำตัวผู้เสียภาษี, สำนักงานใหญ่/สาขา, e-Mail
              Address, ให้ถูกต้องทุกครั้งก่อนกดบันทึกข้อมูลและจ่ายชำระเงิน
            </p>
            <hr className="my-4" />
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="allow_save"
                disabled={this.props.editable === false}
                onChange={(e) =>
                  this.props.onChange({ allow_save: e.target.checked })
                }
              />
              <label className="form-check-label" htmlFor="allow_save">
                ยอมรับเงื่อนไข
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row justify-content-center">
          <button
            type="button"
            className="btn btn-primary prev-step mx-2 w-sm-auto mb-2"
            onClick={() => this.props.changeStep(2)}
          >
            ย้อนกลับ
          </button>
          {this.props.editable === true && (
            <>
              <button
                type="button"
                className="btn btn-primary mx-2 w-sm-auto mb-2"
                onClick={(e) => this.props.onSubmit(e, false)}
                disabled={this.props.data.allow_save === false}
              >
                ส่งข้อมูล
              </button>
              <button
                type="button"
                className="btn btn-primary mx-2 w-sm-auto mb-2"
                onClick={(e) => this.props.onSubmit(e, true)}
                disabled={this.props.data.allow_save === false}
              >
                พิมพ์ใบแจ้งชำระเงิน
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}

MemberFormStep3.defaultProps = {
  data: {
    allow_save: false,
  },
  step1: [],
  step2: {},
  editable: false,
  id: null,
};

export default MemberFormStep3;
