import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { BrowserRouter, Route } from "react-router-dom";
import bg_hero from "../../../assets/images/hero_1.jpg";
import { Services, Auth, Alert } from "../../Service/Services";
import FrontendBoxContainer from "./../../../components/FrontendBoxContainer/FrontendBoxContainer";
import MemberCancelPayment from "./MemberCancelPayment";
import "./Member.css";

const $ = window.$;

class Member extends Component {
  state = {
    datasource: [],
    loading: true,
    url: {
      print_card: "/memberprintcard",
      print_letter: "/memberprintletter",
    },
    allow_register_online: false,
    allow_register_onground: false,
    allow_early_bird_online: false,
    allow_early_bird_offline: false,
    promotions: [],
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const data = {
      ...this.state.data,
      [name]: value,
    };

    this.setState({
      data,
    });
  };

  componentDidMount = () => {
    if (Auth.isAuth() === false) {
      window.location.href = "/";
    }

    this.retrieveData();
  };

  retrieveData = () => {
    const api_url = "member";

    axios.get(api_url).then((res) => {
      const datasource = res.data.entities;
      const allow_register_online = res.data.allow_register_online;
      const allow_register_onground = res.data.allow_register_onground;
      const allow_early_bird_online = res.data.allow_early_bird_online;
      const allow_early_bird_offline = res.data.allow_early_bird_offline;

      const promotions = res.data.promotions;

      this.setState({
        datasource,
        loading: false,
        allow_register_online,
        allow_register_onground,
        allow_early_bird_online,
        allow_early_bird_offline,
        promotions,
      });
    });
  };

  deleteMember = (id) => {
    swal({
      title: "Confirm",
      text: "คุณแน่ใจไหมที่จะทำการลบรายการนี้",
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((res) => {
      if (res === true) {
        this.setState({ loading: true });
        axios.delete("member/" + id).then((res) => {
          this.retrieveData();
        });
      }
    });
  };

  printInvoice = (index, url_invoice, refresh = false) => {
    this.setState({ loading: true });

    axios.get(url_invoice, { responseType: "blob" }).then((res) => {
      let response = res.request.response;
      let url = window.URL.createObjectURL(response);
      window.open(url, "_blank");

      if (refresh === true) {
        let datasource = [...this.state.datasource];
        datasource[index].data.is_printed = true;

        this.setState({ datasource, loading: false });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  printCard = (id) => {
    this.setState({ loading: true });

    const url = `${this.state.url.print_card}/${id}`;

    axios.get(url).then((res) => {
      let url = res.data;
      window.open(url, "_blank");
      this.setState({ loading: false });
    });
  };

  printLetter = (id) => {
    this.setState({ loading: true });

    const url = `${this.state.url.print_letter}/${id}`;

    axios.get(url, { responseType: "blob" }).then((res) => {
      let response = res.request.response;
      let url = window.URL.createObjectURL(response);
      window.open(url, "_blank");
      this.setState({ loading: false });
    });
  };

  cancelPaymentPopup = (id) => {
    window.location.href = "member/cancel/" + id;
  };

  render() {
    let btn_online_register_early_bird,
      btn_onground_register_early_bird,
      btn_online_register,
      btn_onground_register;

    if (this.state.allow_register_onground === true) {
      btn_onground_register = (
        <a
          className="btn btn-warning text-white mb-2 float-right ml-2"
          data-toggle="tooltip"
          data-placemeent="top"
          data-original-title="Create"
          title="Create"
          // href="/member/form"
          href="/member/agreement/offline"
        >
          <i className="fa fa-plus"></i> ลงทะเบียนแบบ Onsite
        </a>
      );
    }

    if (this.state.allow_register_online === true) {
      btn_online_register = (
        <a
          className="btn btn-primary mb-2 float-right ml-2"
          data-toggle="tooltip"
          data-placemeent="top"
          data-original-title="Create"
          title="Create"
          // href="/member/formonline"
          href="/member/agreement/online"
        >
          <i className="fa fa-globe"></i> ลงทะเบียนแบบ Online
        </a>
      );
    }

    if (this.state.allow_early_bird_offline === true) {
      btn_onground_register_early_bird = (
        <a
          className="btn btn-warning text-white mb-2 float-right ml-2"
          data-toggle="tooltip"
          data-placemeent="top"
          data-original-title="Create"
          title="Create"
          // href="/member/earlybird/form"
          href="/member/agreement/offline/earlybird"
        >
          <i className="fa fa-plus"></i> ลงทะเบียนแบบ Onsite (Early Bird)
        </a>
      );
    }

    if (this.state.allow_early_bird_online === true) {
      btn_online_register_early_bird = (
        <a
          className="btn btn-primary mb-2 float-right ml-2"
          data-toggle="tooltip"
          data-placemeent="top"
          data-original-title="Create"
          title="Create"
          // href="/member/earlybird/formonline"
          href="/member/agreement/online/earlybird"
        >
          <i className="fa fa-globe"></i> ลงทะเบียนแบบ Online (Early Bird)
        </a>
      );
    }

    const datasource = this.state.datasource.map((item, index) => {
      const data = item.data;
      let invoice_status_element;
      let row_class_name = [];
      const is_cancel = data.is_cancel;

      if (data.payment_status !== "paid") {
        if (data.is_printed) {
          invoice_status_element = (
            <span className={is_cancel ? "text-danger" : "text-primary"}>
              <i className="fa fa-file-pdf-o">
                {" "}
                <u>
                  <a
                    className={is_cancel ? "text-danger" : "text-primary"}
                    onClick={(e) => this.printInvoice(index, data.url_invoice)}
                    style={{ cursor: "pointer" }}
                  >
                    ใบรายการจ่ายเงิน
                  </a>
                </u>
              </i>
            </span>
          );
        } else {
          invoice_status_element = (
            <span className="text-danger">
              <i className="fa fa-print">
                {" "}
                <u>
                  <a
                    className="text-danger"
                    onClick={(e) =>
                      this.printInvoice(index, data.url_invoice, true)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    พิมพ์ใบรายการจ่ายเงิน
                  </a>
                </u>
              </i>
            </span>
          );
        }
      }

      let buttons = [];

      let allow_edit = false;
      if (
        data.is_online === true &&
        this.state.allow_register_online === true
      ) {
        allow_edit = true;
      } else if (
        data.is_online === false &&
        this.state.allow_register_onground === true
      ) {
        allow_edit = true;
      }

      if (data.is_paid && is_cancel === false && data.is_online === false) {
        allow_edit = false;
        const print_report = (
          <div key="3" className="btn-group" role="group">
            <button
              id="btnGroupDrop1"
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              เอกสาร
            </button>
            <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
              <a
                className="dropdown-item"
                onClick={(e) => this.printCard(item.key)}
              >
                บัตรเข้าร่วมงาน
              </a>
              <a
                className="dropdown-item"
                onClick={(e) => this.printLetter(item.key)}
              >
                จดหมายเข้าร่วมงาน
              </a>
            </div>
          </div>
        );

        buttons.push(print_report);
        // buttons.push(<a key='4' className="btn btn-danger" onClick={ e => this.cancelPaymentPopup(item.key)}><i className='fa fa-times'></i> ขอคืนเงินค่าลงทะเบียน</a>)
      }

      if (data.is_printed === true) allow_edit = false;

      if (allow_edit === true && is_cancel === false) {
        if (data.is_online === false) {
          buttons.push(
            <a
              key="1"
              className="btn btn-secondary"
              href={"member/form/" + item.key + "/edit"}
            >
              <i className="fa fa-pencil"></i>
            </a>
          );
        } else {
          buttons.push(
            <a
              key="1"
              className="btn btn-secondary"
              href={"member/formonline/" + item.key}
            >
              <i className="fa fa-pencil"></i>
            </a>
          );
        }

        buttons.push(
          <a
            key="2"
            className="btn btn-danger"
            onClick={(e) => this.deleteMember(item.key)}
          >
            <i className="fa fa-trash"></i>
          </a>
        );
      }

      let payment_status_element;

      if (data.payment_status === "paid") {
        payment_status_element = (
          <span className="badge badge-primary">
            {data.payment_status_text}
          </span>
        );
      } else {
        payment_status_element = (
          <span className="badge badge-danger">{data.payment_status_text}</span>
        );
      }

      if (is_cancel === true) {
        row_class_name.push("text-danger");

        payment_status_element = (
          <span className="badge badge-danger">ขอคืนเงิน</span>
        );

        buttons.push(
          <u key="5">
            <a
              href="#"
              className="text-danger"
              onClick={(e) => this.cancelPaymentPopup(item.key)}
            >
              <i className="fa fa-info"></i> รายละเอียดการขอคืนเงิน
            </a>
          </u>
        );
      }

      buttons.push(
        <a
          key="6"
          className="btn btn-info"
          href={"member/form/" + item.key + "/view"}
        >
          <i className="fa fa-eye"></i>
        </a>
      );

      const button_group = (
        <div className="btn-group" role="group">
          {buttons}
        </div>
      );
      let register_type, early_bird;

      if (data.is_online === true) {
        register_type = <span className="badge badge-success">Online</span>;
      } else {
        register_type = <span className="badge badge-warning">Onsite</span>;
      }

      if (data.is_early_bird === true) {
        early_bird = <div className="text-muted">Early Bird</div>;
      }

      return (
        <tr key={index} className={row_class_name.join(" ")}>
          <td className="text-center">{data.register_date}</td>
          <td className="text-center">
            {register_type}
            {early_bird}
          </td>
          <td className="text-right">{data.count}</td>
          <td className="text-center">{payment_status_element}</td>
          <td className="text-center">{invoice_status_element}</td>
          <td className="text-center text-white">
            <div className="mb-2">{button_group}</div>
          </td>
        </tr>
      );
    });

    return (
      <BrowserRouter>
        <FrontendBoxContainer
          loading={this.state.loading}
          title="ผู้เข้าร่วมงาน"
        >
          <Route
            exact
            path={`${this.props.match.path}/`}
            component={(props) => (
              <>
                {this.state.promotions.length > 0 && (
                  <div className="alert alert-warning" role="alert">
                    <h4 className="alert-heading">Promotions !</h4>
                    <p>
                      <ul className="text-left">
                        {this.state.promotions.map((promotion) => (
                          <li key={promotion.id}>
                            {promotion.name}{" "}
                            <span
                              className={
                                promotion.is_online
                                  ? "badge badge-success"
                                  : "badge badge-warning"
                              }
                            >
                              {promotion.is_online ? "Online" : "Onsite"}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </p>
                  </div>
                )}
                <form action="#" className="p-5 bg-white text-left">
                  <h2 className="h4 text-black mb-5 text-center">
                    ผู้เข้าร่วมงาน
                  </h2>
                  <div className="row form-group">
                    <div className="col-md-12 mb-3 mb-md-0">
                      {btn_online_register_early_bird}
                      {btn_onground_register_early_bird}
                      {btn_online_register}
                      {btn_onground_register}
                      <div className="table-responsive">
                        <table className="table w-100">
                          <thead>
                            <tr>
                              <th className="text-center">วันที่ลงทะเบียน</th>
                              <th className="text-center">ประเภท</th>
                              <th className="text-center">จำนวนสมาชิก (คน)</th>
                              <th className="text-center">สถานะ</th>
                              <th className="text-center">
                                การพิมพ์ใบแจ้งหนี้
                              </th>
                              <th className="text-center">#</th>
                            </tr>
                          </thead>
                          <tbody>{datasource}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </form>
              </>
            )}
          />
        </FrontendBoxContainer>
      </BrowserRouter>
    );
  }
}

export default Member;
