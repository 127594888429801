import React, { useEffect, useRef, useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import { UserDeliveryProfileAPI } from "../../api";
import { Alert } from "../../pages/Service/Services";

const $ = window.$;

const AddressItem = ({
  address,
  onSelectProfile,
  setIsLoading,
  onDelete,
  onEdit,
}) => {
  const actions = (
    <div className="">
      <button
        type="button"
        className="btn-sm float-right btn btn-link text-danger text-link"
        onClick={() => {
          Alert.confirm("คุณแน่ใจไหมที่จะลบ").then((res) => {
            if (res === true) {
              setIsLoading(true);
              UserDeliveryProfileAPI.deleteUserDeliveryProfile(address.id).then(
                () => {
                  Alert.done("ลบเสร็จสิ้น");
                  onDelete();
                }
              );
            }
          });
        }}
      >
        <i className="fa fa-trash"></i> ลบ
      </button>
      <button
        type="button"
        className="btn-sm float-right btn btn-link text-success text-link"
        onClick={onEdit}
      >
        <i className="fa fa-pencil"></i> แก้ไข
      </button>
    </div>
  );
  return (
    <div className="card mb-3">
      <h5 className="card-header bg-secondary text-white">
        <button
          className="btn btn-link text-white text-link float-right"
          onClick={onSelectProfile}
        >
          <i className="fa fa-check"></i> เลือก
        </button>
        {address.name}{" "}
        {address.address_type === "personal" ? (
          <span className="badge badge-warning p-2">ออกใบเสร็จในนามบุคคล</span>
        ) : (
          <span className="badge badge-danger p-2">
            ออกใบเสร็จในนาม รพ./หน่วยงาน/บริษัท
          </span>
        )}
      </h5>
      <div className="card-body">
        <p className="card-text">
          {address.address_type === "company" && (
            <>
              <strong>สาขา: </strong> {address.branch_code || "สำนักงานใหญ่"}{" "}
              {address.branch_name} <br />
              <strong>เลขประจำตัวผู้เสียภาษี: </strong> {address.identity_no}
              <br />
            </>
          )}
          <strong>ที่อยู่: </strong> {address.address} แขวง/ตำบล
          {address.sub_district} เขต/อำเภอ{address.district} จังหวัด
          {address.province_name} รหัสไปรษณีย์ {address.zip_code}
        </p>
      </div>
      <div className="card-footer">{actions}</div>
    </div>
  );
};

const AddNewAddress = React.forwardRef(
  ({ datasource, company_profile, setIsLoading, onSaved, address }, ref) => {
    const defaultData = {
      address_type: "personal",
      address: "",
      sub_district: "",
      district: "",
      province_id: "",
      zip_code: "",
      name: "",
      identity_no: "",
      branch_code: "",
      branch_name: "",
    };

    const [data, setData] = useState(defaultData);

    const isValid = () => {
      const errors = [];

      if (data.address.trim() === "") {
        errors.push("กรุณาระบุ ที่อยู่");
      }
      if (data.sub_district.trim() === "") {
        errors.push("กรุณาระบุ ตำบล");
      }
      if (data.district.trim() === "") {
        errors.push("กรุณาระบุ อำเภอ");
      }
      if (data.province_id === null) {
        errors.push("กรุณาระบุ จังหวัด");
      }
      if (data.zip_code.trim() === "") {
        errors.push("กรุณาระบุ รหัสไปรษณีย์");
      }

      if (data.address_type === "company") {
        if (data.name.trim() === "") {
          errors.push("กรุณาระบุ ชื่อในการออกใบเสร็จ");
        }
        if (data.identity_no.trim() === "") {
          errors.push("กรุณาระบุ เลขประจำตัวผู้เสียภาษี");
        } else if (data.identity_no.trim().length !== 13) {
          errors.push("กรุณาระบุ เลขประจำตัวผู้เสียภาษี ต้องมี 13 หลัก");
        }
        if (data.branch_code.trim() === "" || data.branch_code === null) {
          errors.push("กรุณาระบุ รหัสสาขา");
        } else if (data.branch_code.trim().length !== 5) {
          errors.push("รหัสสาขาต้องมี 5 ตัว");
        } else if (
          (data.branch_name === null || data.branch_name.trim() === "") &&
          data.branch_code !== "00000"
        ) {
          errors.push("กรุณาระบุ ชื่อสาขา");
        }
      }

      if (errors.length === 0) return true;

      Alert.error(errors.join("\n"));

      return false;
    };

    const onSave = () => {
      if (isValid() === false) return;
      Alert.confirm("คุณแน่ใจไหมที่จะบันทึกข้อมูล").then((res) => {
        if (res === true) {
          setIsLoading(true);
          UserDeliveryProfileAPI.updateUserDeliveryProfile(
            data,
            address.id
          ).then(() => {
            Alert.done("บันทึกข้อมูลเรียบร้อย").then(() => {
              $(ref.current).modal("hide");
              setData(defaultData);
              onSaved();
            });
          });
        }
      });
    };

    useEffect(() => {
      if (address.id) {
        setData(address);
      } else {
        setData(defaultData);
      }
    }, [JSON.stringify(address)]);

    const onDDLChange = (obj) => {
      let newData = { ...data, ...obj };
      setData(newData);
    };

    const onChange = (e) => {
      const { name, value } = e.target;

      let newData = { ...data };
      newData[name] = value;

      if (name === "address_type" && value === "company") {
        newData.name = company_profile.receipt_name;
        newData.address = company_profile.receipt_address;
        newData.district = company_profile.receipt_district;
        newData.sub_district = company_profile.receipt_sub_district;
        newData.zip_code = company_profile.receipt_zip_code;
        newData.province_id = company_profile.receipt_province_id;
      }

      setData(newData);
    };

    const address_type_company = (
      <>
        <div className="row form-group">
          <div className="col-md-6 mb-3 mb-md-0">
            <label className="text-black field-required" htmlFor="name">
              ชื่อในการออกใบเสร็จ
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              value={data.name}
              onChange={onChange}
              required
            />
          </div>
          <div className="col-md-6 mb-3 mb-md-0">
            <label className="text-black field-required" htmlFor="identity_no">
              เลขประจำตัวผู้เสียภาษี
            </label>
            <input
              type="text"
              id="identity_no"
              name="identity_no"
              className="form-control"
              value={data.identity_no}
              onChange={onChange}
              required
              maxLength={13}
              minLength={13}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-6 mb-3 mb-md-0">
            <label className="text-black field-required" htmlFor="branch_code">
              รหัสสาขา
            </label>
            <input
              type="text"
              id="branch_code"
              name="branch_code"
              className="form-control"
              value={data.branch_code}
              onChange={onChange}
              maxLength={5}
              minLength={5}
              required
            />
            <small>
              * หากเป็นสำนักงานใหญ่กรุณากรอก 00000
              หรือถ้าเป็นสาขากรุณากรอกรหัสสาขาเช่น 00001)
            </small>
          </div>
          <div className="col-md-6 mb-3 mb-md-0">
            <label className="text-black" htmlFor="branch_name">
              ชื่อสาขา
            </label>
            <input
              type="text"
              id="branch_name"
              name="branch_name"
              className="form-control"
              value={data.branch_name}
              onChange={onChange}
            />
          </div>
        </div>
      </>
    );

    return (
      <>
        <div
          ref={ref}
          className="modal fade"
          id="modalUpdateAddress"
          tabIndex="-1"
          aria-labelledby="modalUpdateAddressLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header bg-secondary text-white">
                <h5 className="modal-title" id="modalUpdateAddressLabel">
                  ที่อยู่
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="text-left p-2">
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="text-black mr-2" htmlFor="address_type">
                        การออกใบเสร็จ
                      </label>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input
                            onChange={onChange}
                            checked={
                              data.address_type === "personal" ? true : false
                            }
                            required="required"
                            type="radio"
                            name="address_type"
                            className="form-check-input"
                            value="personal"
                          />
                          ออกใบเสร็จในนามบุคคล
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input
                            onChange={onChange}
                            checked={
                              data.address_type === "company" ? true : false
                            }
                            required="required"
                            type="radio"
                            name="address_type"
                            className="form-check-input"
                            value="company"
                          />
                          ออกใบเสร็จในนาม รพ./หน่วยงาน/บริษัท
                        </label>
                      </div>
                    </div>
                  </div>
                  {data.address_type === "company" && address_type_company}
                  <div className="row form-group">
                    <div className="col-md-12 mb-3 mb-md-0">
                      <label
                        className="text-black field-required"
                        htmlFor="address"
                      >
                        ที่อยู่
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        className="form-control"
                        value={data.address}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-6 mb-3 mb-md-0">
                      <label
                        className="text-black field-required"
                        htmlFor="sub_district"
                      >
                        แขวง/ตำบล
                      </label>
                      <input
                        type="text"
                        id="sub_district"
                        name="sub_district"
                        className="form-control"
                        value={data.sub_district}
                        onChange={onChange}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3 mb-md-0">
                      <label
                        className="text-black field-required"
                        htmlFor="district"
                      >
                        เขต/อำเภอ
                      </label>
                      <input
                        type="text"
                        id="district"
                        name="district"
                        className="form-control"
                        value={data.district}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-6 mb-3 mb-md-0">
                      <label
                        className="text-black field-required"
                        htmlFor="province_id"
                      >
                        จังหวัด
                      </label>
                      <Dropdown
                        datasource={datasource.province}
                        name="province_id"
                        value={data.province_id}
                        onChange={onDDLChange}
                        placeholder="กรุณาเลือก"
                        required={true}
                      ></Dropdown>
                    </div>
                    <div className="col-md-6 mb-3 mb-md-0">
                      <label
                        className="text-black field-required"
                        htmlFor="zip_code"
                      >
                        รหัสไปรษณีย์
                      </label>
                      <input
                        type="text"
                        id="zip_code"
                        name="zip_code"
                        className="form-control"
                        value={data.zip_code}
                        onChange={onChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer flex-column flex-sm-row">
                <button
                  type="button"
                  className="btn btn-secondary w-100 w-sm-auto mb-2"
                  data-dismiss="modal"
                >
                  ยกเลิก
                </button>
                <button
                  type="button"
                  className="btn btn-primary w-100 w-sm-auto mb-2"
                  onClick={onSave}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

const DeliveryProfile = ({ label, onSelect, datasource, company_profile }) => {
  const delivery_profile_element = useRef(null);
  const delivery_profile_address_element = useRef(null);
  const [userDeliveryProfile, setUserDeliveryProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editedData, setEditedData] = useState({});
  const [toggleModal, setToggleModal] = useState(false);

  const retrieveData = () => {
    UserDeliveryProfileAPI.getAllUserDeliveryProfile().then((res) => {
      setUserDeliveryProfile(res.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    retrieveData();
  }, []);

  useEffect(() => {
    if (toggleModal === true) {
      $(delivery_profile_element.current).on("hidden.bs.modal", () => {
        $(delivery_profile_address_element.current).modal("show");
        $(delivery_profile_element.current).unbind("hidden.bs.modal");
      });

      $(delivery_profile_address_element.current).on("hidden.bs.modal", () => {
        $(delivery_profile_element.current).modal("show");
        $(delivery_profile_address_element.current).unbind("hidden.bs.modal");
        setToggleModal(false);
      });

      $(delivery_profile_element.current).modal("hide");
    }
  }, [toggleModal]);

  const onSelectProfile = (index) => {
    onSelect(userDeliveryProfile[index]);
    $(delivery_profile_element.current).modal("hide");
  };

  const onAddNewAddressClick = () => {
    $(delivery_profile_element.current).modal("hide");
    // delivery_profile_address_element.current
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary btn-block"
        data-toggle="modal"
        data-target="#modalAddress"
      >
        <i className="fa fa-check"></i> {label}
      </button>

      <AddNewAddress
        company_profile={company_profile}
        ref={delivery_profile_address_element}
        onAddNewAddressClick={onAddNewAddressClick}
        address={editedData}
        datasource={datasource}
        setIsLoading={setIsLoading}
        onSaved={() => {
          retrieveData();
          $(delivery_profile_address_element.current).modal("hide");
        }}
      />
      <div
        ref={delivery_profile_element}
        className="modal fade"
        id="modalAddress"
        tabIndex="-1"
        aria-labelledby="modalAddressLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header bg-secondary text-white">
              <h5 className="modal-title" id="modalAddressLabel">
                ที่อยู่
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {isLoading === true
                ? "...Loading"
                : userDeliveryProfile.map((address, addressIndex) => (
                    <AddressItem
                      onDelete={retrieveData}
                      onEdit={() => {
                        setToggleModal(true);
                        setEditedData(address);
                      }}
                      key={addressIndex}
                      address={address}
                      onSelectProfile={() => onSelectProfile(addressIndex)}
                      setIsLoading={setIsLoading}
                    />
                  ))}
              <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={() => {
                  setToggleModal(true);
                  setEditedData({});
                }}
              >
                <i className="fa fa-plus"></i> เพิ่มใหม่
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryProfile;
