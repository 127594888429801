import React, { Component } from "react";
import { authenticationService } from "../../pages/Authentication/Service/authentication.service";
import default_logo from "../../assets/images/default_logo.png";
import axios from "../../axios";
import swal from "sweetalert";
import cookie from "js-cookie";
import { Auth } from "../../pages/Service/Services";
const $ = window.$;

class Navbar extends Component {
  state = {
    username: "",
    password: "",
    authen: Auth.isAuth(),
    menues: {
      venue: [],
    },
  };

  onChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  componentDidMount = () => {
    // const authen = Auth.isAuth();

    axios.get("venue").then((res) => {
      const venue = res.data;
      const menues = {
        ...this.state.menues,
        venue,
      };

      this.setState({
        // authen,
        menues,
      });
    });

    $("#loginModal").on("show.bs.modal", function () {
      $("body").removeClass("offcanvas-menu");
    });
  };

  login = (e) => {
    e.preventDefault();

    const { username, password } = this.state;

    authenticationService
      .login(username, password)
      .then((res) => {
        if (res && res.otp) {
          // request otp
          const reference_no = res.otp.reference_no;

          swal({
            title: "OTP",
            text:
              "กรุณากรอกหมายเลข OTP เพื่อยืนยันตัวตน หมายเลขอ้างอิง/Reference No: " +
              reference_no,
            icon: "info",
            button: "OK",
            content: {
              element: "input",
              attributes: {
                placeholder: "OTP Code",
                type: "password",
              },
            },
          }).then((otp_code) => {
            authenticationService
              .login(username, password, otp_code, reference_no)
              .then(() => {
                // window.location.reload()
                window.location.href = "/user/profile";
              })
              .catch((res) => {
                const error_message = res.response.data;
                swal({
                  title: "Error",
                  text: error_message,
                  icon: "error",
                  button: "OK",
                });
              });
          });
        } else {
          window.location.reload();
        }
      })
      .catch((res) => {
        swal({
          title: "Error",
          text: res.data,
          icon: "error",
          button: "OK",
        });
      });
  };

  logout() {
    cookie.remove("_token_forum");
    window.location.reload();
  }
  render() {
    const authen = this.state.authen;
    let login, member, abstract, register, workshop, profile;

    if (authen === true) {
      const user = Auth.getCurrentUser();
      profile = (
        <li>
          <div className="dropdown">
            <button
              className="btn btn-link dropdown-toggle pt-0"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-user-circle-o"></i> {user.name}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href={"/user/profile"}>
                ประวัติส่วนตัว
              </a>
              <a className="dropdown-item" href={"/user/changepassword"}>
                แก้ไขรหัสผ่าน
              </a>
              <a className="dropdown-item" onClick={this.logout}>
                ออกจากระบบ
              </a>
            </div>
          </div>
        </li>
      );
    }

    let mainMenues = [
      { name: "หน้าหลัก", href: "/", active: false, show: true },
      { name: "ส่งผลงาน", href: "/abstract", active: false, show: authen },
      {
        name: "แนะนำสถานที่",
        href: "#",
        childs: [
          ...this.state.menues.venue.map((subMenu) => {
            return { name: subMenu.name, href: "/venue/" + subMenu.id };
          }),
          { name: "แนะนำที่พัก", href: "/hotel" },
        ],
        active: false,
        show: true,
      },
      {
        name: "เอกสารเผยแพร่",
        href: "/publishdocument",
        active: false,
        show: true,
      },
      { name: "กำหนดการ", href: "/agenda", active: false, show: true },

      { name: "Workshop", href: "/workshop", active: false, show: authen },
      {
        name: "เข้าสู่ระบบ",
        href: "#",
        active: false,
        show: authen === false,
        modalId: "loginModal",
      },
      {
        name: "ลงทะเบียนเข้าร่วมงาน",
        href: "/member",
        active: false,
        show: authen,
      },
      {
        name: "สมัครสมาชิก",
        href: "/register",
        active: false,
        show: authen === false,
      },

      { name: "FAQ", href: "/faq", active: false, show: true },
    ];

    const showMenu = mainMenues
      .filter((f) => f.show)
      .map((menu, menuKey) => {
        const url = window.location.pathname;

        if (url === "/" && menu.href === url) {
          menu.active = true;
        } else if (menu.href !== "/" && url.indexOf(menu.href) >= 0) {
          menu.active = true;
        }

        if (menu.childs !== undefined && menu.childs.length > 0) {
          const subMenu = menu.childs.map((subMenu, subMenuKey) => {
            return (
              <li key={subMenuKey}>
                <a href={subMenu.href}>{subMenu.name}</a>
              </li>
            );
          });

          return (
            <li
              key={menuKey}
              className={"has-children " + (menu.active ? "active" : "")}
            >
              <a href="#">{menu.name}</a>
              <ul className="dropdown">{subMenu}</ul>
            </li>
          );
        } else if (menu.modalId !== undefined) {
          return (
            <li key={menuKey}>
              <a href="#" data-toggle="modal" data-target={"#" + menu.modalId}>
                {menu.name}
              </a>
            </li>
          );
        } else {
          return (
            <li key={menuKey} className={menu.active ? "active" : ""}>
              <a href={menu.href}>{menu.name}</a>
            </li>
          );
        }
      });

    return (
      <div>
        <div className="site-mobile-menu">
          <div className="site-mobile-menu-header">
            <div className="site-mobile-menu-close mt-3">
              <span className="icon-close2 js-menu-toggle"></span>
            </div>
          </div>
          <div className="site-mobile-menu-body"></div>
        </div>

        <div className="border-bottom top-bar py-2">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <p className="mb-0">
                  <span className="mr-3">
                    <strong>Phone:</strong>{" "}
                    <a href="tel:+66028329400">(+66) 0 2027 8844 </a>
                  </span>
                  <span>
                    <strong>Email:</strong>{" "}
                    <a href="mailto:hawebmaster@ha.or.th">
                      hawebmaster@ha.or.th
                    </a>
                  </span>
                </p>
              </div>
              <div className="col-md-6">
                <ul className="social-media">
                  <li>
                    <a
                      target="_blank"
                      href="https://web.facebook.com/HATHAILANDfanpage/"
                      className="p-2"
                    >
                      <span className="icon-facebook"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/user/HAIThailand"
                      className="p-2"
                    >
                      <span className="icon-youtube"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="http://line.me/ti/p/~@hathailand"
                      className="p-2"
                    >
                      <i className="lab la-line lg"></i>
                    </a>
                  </li>
                  {profile}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <header className="site-navbar py-4 bg-white" role="banner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-10 col-md-2 col-xl-1">
                <h1 className="mb-0 site-logo">
                  <a href="/" className="text-black h2 mb-0">
                    <img src={default_logo} style={{ width: "80px" }} />
                  </a>
                </h1>
              </div>
              <div className="col-12 col-md-10 col-xl-11 d-none d-xl-block">
                <nav
                  className="site-navigation position-relative text-right"
                  role="navigation"
                >
                  <ul className="pl-0 site-menu js-clone-nav mr-auto d-none d-lg-block">
                    {showMenu}
                  </ul>
                </nav>
              </div>
              <div
                className="d-inline-block d-xl-none ml-md-0 mr-auto py-3"
                style={{ position: "relative", top: "3px" }}
              >
                <a
                  href="#"
                  className="site-menu-toggle js-menu-toggle text-black"
                >
                  <span className="icon-menu h3"></span>
                </a>
              </div>
            </div>
          </div>
        </header>
        <div
          className="modal fade"
          id="loginModal"
          role="dialog"
          aria-labelledby="loginModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-primary text-white">
                <h5 className="modal-title" id="loginModalLabel">
                  Login
                </h5>
                <button
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.login}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="loginInputEmail">Email / Username</label>
                    <input
                      name="username"
                      type="text"
                      className="form-control"
                      id="loginInputEmail"
                      aria-describedby="emailHelp"
                      placeholder="Enter email or username"
                      value={this.state.username}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="loginInputPassword">รหัสผ่าน</label>
                    <input
                      name="password"
                      type="password"
                      className="form-control"
                      id="loginInputPassword"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group form-check"></div>
                  <div className="alert alert-warning">
                    <i
                      className="fa fa-exclamation-triangle"
                      aria-hidden="true"
                    ></i>
                    &nbsp; ระบบจะมีการส่ง OTP Code เพื่อยืนยันตัวตนหลังจาก Login
                    ผ่านทางอีเมล
                  </div>
                </div>
                <div className="modal-footer flex-column flex-sm-row">
                  <a
                    href="/auth/forgetpassword"
                    className="btn btn-link mb-2 w-100 w-sm-auto"
                  >
                    ลืมรหัสผ่าน
                  </a>
                  <a
                    href="/register"
                    className="btn btn-link mb-2 w-100 w-sm-auto"
                  >
                    สมัครสมาชิก
                  </a>
                  <button
                    type="button"
                    className="btn btn-secondary mb-2 w-100 w-sm-auto"
                    data-dismiss="modal"
                  >
                    ปิด
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary mb-2 w-100 w-sm-auto"
                  >
                    เข้าสู่ระบบ
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
