import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import Dropdown from "../../../components/Dropdown/Dropdown";
import DeliveryProfile from "../../../components/DeliveryProfile/DeliveryProfile";

var HtmlToReactParser = require("html-to-react").Parser;

const ReceiptAddressInfo = ({ address }) => {
  return (
    <div className="card mb-3 mt-2">
      <h5 className="card-header bg-secondary text-white">
        {address.receipt_name}{" "}
        {address.receipt_address_type === "personal" ? (
          <span className="badge badge-warning p-2">ออกใบเสร็จในนามบุคคล</span>
        ) : (
          <span className="badge badge-danger p-2">
            ออกใบเสร็จในนาม รพ./หน่วยงาน/บริษัท
          </span>
        )}
      </h5>
      <div className="card-body">
        <p className="card-text">
          {address.receipt_address_type === "company" && (
            <>
              <strong>สาขา: </strong>{" "}
              {address.receipt_branch_code || "สำนักงานใหญ่"}{" "}
              {address.receipt_branch_name} <br />
              <strong>เลขประจำตัวผู้เสียภาษี: </strong>{" "}
              {address.receipt_identity_no} <br />
            </>
          )}
          <strong>ที่อยู่: </strong> {address.receipt_address} แขวง/ตำบล
          {address.receipt_sub_district} เขต/อำเภอ
          {address.receipt_district} จังหวัด
          {address.receipt_province_name} รหัสไปรษณีย์{" "}
          {address.receipt_zip_code}
        </p>
      </div>
    </div>
  );
};
class MemberFormStep2 extends Component {
  state = {
    datasource: {
      province: [],
    },
    company_profile: {},
  };

  onChange = (e) => {
    const { name, value } = e.target;

    let data = { ...this.props.data };
    data[name] = value;

    this.props.onChange(data);
  };

  onDDLChange = (obj) => {
    let data = { ...this.props.data, ...obj };

    this.props.onChange(data);
  };

  getAPIDatasource = () => {
    const api_url = "datasource";
    const params = {
      tables: [{ name: "province" }],
    };

    const promise = axios
      .post(api_url, params)
      .then((res) => {
        const datasource = {
          province: res.data.province.map((item) => {
            return { key: item.id, label: item.name };
          }),
        };

        return datasource;
      })
      .catch((res) => {
        console.log("error", res);
      });

    return promise;
  };

  getCompanyProfile = () => {
    const promise = axios.get("company_profile").then((res) => {
      const company_profile = res.data;

      return company_profile;
    });

    return promise;
  };

  componentDidMount = () => {
    this.getAPIDatasource().then((result) => {
      this.setState({
        datasource: result,
      });
    });

    this.getCompanyProfile().then((result) => {
      this.setState({
        company_profile: result,
      });
    });
  };

  onSelectDeliveryProfile = (newData) => {
    this.props.onChange({
      ...this.props.data,
      receipt_user_delivery_profile_id: newData.id,
      receipt_name: newData.name,
      receipt_identity_no: newData.identity_no,
      receipt_address: newData.address,
      receipt_district: newData.district,
      receipt_sub_district: newData.sub_district,
      receipt_province_id: newData.province_id,
      receipt_province_name: newData.province_name,
      receipt_zip_code: newData.zip_code,
      receipt_address_type: newData.address_type,
      receipt_branch_code: newData.branch_code,
      receipt_branch_name: newData.branch_name,
    });
  };

  render() {
    let data = this.props.data;

    data.document_name = data.document_name || "";
    data.document_address = data.document_address || "";
    data.document_district = data.document_district || "";
    data.document_sub_district = data.document_sub_district || "";
    data.document_province_id = data.document_province_id || "";
    data.document_zip_code = data.document_zip_code || "";

    data.receipt_user_delivery_profile_id =
      data.receipt_user_delivery_profile_id || null;
    data.receipt_name = data.receipt_name || "";
    data.receipt_identity_no = data.receipt_identity_no || "";
    data.receipt_address = data.receipt_address || "";
    data.receipt_district = data.receipt_district || "";
    data.receipt_sub_district = data.receipt_sub_district || "";
    data.receipt_province_id = data.receipt_province_id || "";
    data.receipt_zip_code = data.receipt_zip_code || "";
    data.receipt_branch_code = data.receipt_branch_code || "";
    data.receipt_branch_name = data.receipt_branch_name || "";

    return (
      <div className="tab-pane" role="tabpanel" id="step2">
        <h3>ที่อยู่ในการออกใบเสร็จ</h3>
        <div className="text-left">
          {this.props.editable === true && (
            <DeliveryProfile
              label="เลือกที่อยู่"
              onSelect={this.onSelectDeliveryProfile}
              datasource={this.state.datasource}
              company_profile={this.state.company_profile}
            />
          )}
          {this.props.data.receipt_address ? (
            <ReceiptAddressInfo address={this.props.data} />
          ) : (
            <div className="alert alert-danger mt-2">
              <i className="fa fa-warning"></i> กรุณากด "เลือกที่อยู่"
              เพื่อเลือกที่อยู่สำหรับออกใบเสร็จ
            </div>
          )}
        </div>
        <hr />
        <h3>ที่อยู่ในการจัดส่งเอกสาร</h3>
        <div className="text-left p-5">
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    type="radio"
                    onChange={this.onChange}
                    checked={
                      data.delivery_document_type === "receipt" ? true : false
                    }
                    name="delivery_document_type"
                    className="form-check-input"
                    value="receipt"
                    disabled={this.props.editable === false}
                  />
                  ใช้ที่อยู่ในการออกใบเสร็จ
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    type="radio"
                    onChange={this.onChange}
                    checked={
                      data.delivery_document_type === "other" ? true : false
                    }
                    name="delivery_document_type"
                    className="form-check-input"
                    value="other"
                    disabled={this.props.editable === false}
                  />
                  ใช้ที่อยู่อื่น
                </label>
              </div>
            </div>
          </div>
          {data.delivery_document_type === "other" && (
            <div>
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label className="text-black" htmlFor="document_name">
                    ชื่อผู้รับ
                  </label>
                  <input
                    type="text"
                    id="document_name"
                    name="document_name"
                    value={data.document_name}
                    onChange={this.onChange}
                    className="form-control"
                    disabled={this.props.editable === false}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label className="text-black" htmlFor="document_address">
                    ที่อยู่
                  </label>
                  <input
                    type="text"
                    id="document_address"
                    name="document_address"
                    value={data.document_address}
                    onChange={this.onChange}
                    className="form-control"
                    disabled={this.props.editable === false}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6 mb-3 mb-md-0">
                  <label className="text-black" htmlFor="document_sub_district">
                    แขวง/ตำบล
                  </label>
                  <input
                    type="text"
                    id="document_sub_district"
                    name="document_sub_district"
                    value={data.document_sub_district}
                    onChange={this.onChange}
                    className="form-control"
                    disabled={this.props.editable === false}
                  />
                </div>
                <div className="col-md-6 mb-3 mb-md-0">
                  <label className="text-black" htmlFor="document_district">
                    เขต/อำเภอ
                  </label>
                  <input
                    type="text"
                    id="document_district"
                    name="document_district"
                    value={data.document_district}
                    onChange={this.onChange}
                    className="form-control"
                    disabled={this.props.editable === false}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6 mb-3 mb-md-0">
                  <label className="text-black" htmlFor="document_province_id">
                    จังหวัด
                  </label>
                  <Dropdown
                    datasource={this.state.datasource.province}
                    name="document_province_id"
                    value={data.document_province_id}
                    onChange={this.onDDLChange}
                    placeholder="กรุณาเลือก"
                    disabled={this.props.editable === false}
                  ></Dropdown>
                </div>
                <div className="col-md-6 mb-3 mb-md-0">
                  <label className="text-black" htmlFor="document_zip_code">
                    รหัสไปรษณีย์
                  </label>
                  <input
                    type="text"
                    id="document_zip_code"
                    name="document_zip_code"
                    value={data.document_zip_code}
                    onChange={this.onChange}
                    className="form-control"
                    disabled={this.props.editable === false}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-column flex-sm-row justify-content-center">
          <button
            type="button"
            className="btn btn-primary prev-step mx-2 w-sm-auto mb-2"
            onClick={() => this.props.changeStep(1)}
          >
            ย้อนกลับ
          </button>
          <button
            type="button"
            disabled={this.props.data.receipt_user_delivery_profile_id === null}
            className="btn btn-primary next-step mx-2 w-sm-auto mb-2"
            onClick={() => this.props.changeStep(3)}
          >
            ถัดไป
          </button>
        </div>
      </div>
    );
  }
}

MemberFormStep2.defaultProps = {
  data: {
    receipt_user_delivery_profile_id: null,
    receipt_address_type: "personal",
    receipt_name: "",
    receipt_identity_no: "",
    receipt_address: "",
    receipt_district: "",
    receipt_sub_district: "",
    receipt_zip_code: "",
    receipt_province_id: "",
    receipt_province_name: "",
    receipt_branch_code: "",
    receipt_branch_name: "",

    document_name: "",
    document_address: "",
    document_district: "",
    document_sub_district: "",
    document_province_id: "",
    document_zip_code: "",
    delivery_document_type: "receipt",
  },
  editable: false,
};

export default MemberFormStep2;
